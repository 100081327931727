require('./bootstrap');
import 'bootstrap';
import 'gijgo';
import moment from 'moment';

jQuery(function ($) {
  'use strict'

  var ENTER_KEY = 13
  var ESCAPE_KEY = 27
  var MAX_COL = 12

  var util = {
    uuid: function () {
      /*jshint bitwise:false */
      var i, random
      var uuid = ''

      for (i = 0; i < 32; i++) {
        random = (Math.random() * 16) | 0
        if (i === 8 || i === 12 || i === 16 || i === 20) {
          uuid += '-'
        }
        uuid += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(
          16
        )
      }

      return uuid
    },
    pluralize: function (count, word) {
      return count === 1 ? word : word + 's'
    },
    store: function (namespace, data) {
      if (arguments.length > 1) {
        return localStorage.setItem(namespace, JSON.stringify(data))
      } else {
        var store = localStorage.getItem(namespace)
        return (store && JSON.parse(store)) || []
      }
    }
  }

  var App = {
    init: function () {
      this.routes = [];
      this.metaList = [];
      this.packages = [];
      this.originalMetaList = [];
      this.metaCurrentSearch = '';
      this.metaTree = undefined;
      $.ajaxSetup({
        headers: {
          'X-CSRF-TOKEN': document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute('content'),
        },
      })

      $.getJSON(
        'actions/routes',
        function (data) {
          this.routes = data.routes;
          this.loadContents();
          this.bindings();
        }.bind(this)
      )
    },
    bindings: function() {
      $('body .container').on('click', '.btn.spin-on-click', this.prependSpinner.bind(this));
    },
    prependSpinner: function(e) {
      let $el = $(e.currentTarget);
      if($el.find('i').length == 0) {
        $el.prepend('<i class="fa-solid fa-spinner fa-spin"></i> ');
      }
    },
    loadContents: function() {
      $.getJSON(this.routes['sf.meta.status'], result => {
        if(result.sfloggedin) {
          $.getJSON(this.routes['sf.meta.data.all'], result => {
            console.log("sf.meta.data.all", result);

            if(result && result.status == "ok") {
              this.loadAutoTree();
            }
          });
          $.getJSON(this.routes['sf.meta.packages'], result => {
            console.log("sf.meta.packages", result);
            if(result && result.packages) {
              this.packages = result.packages;
            }
          });
        }
      });

      if($('.metacontainer .metalist').length > 0) {
        $.getJSON(this.routes['sf.meta.data'], result => {
          this.metaList = result.data;
          this.originalMetaList = result.data;

          let selected = $('#sf_meta_selected').val();
          if(selected) {
            let sel_list = selected.replace(' ', '').split(',');
            for(let i in sel_list) {
              this.metaList = this.metaList.filter((value, index, arr) => {
                return value != sel_list[i];
              });
            }
          }

          this.refillMetaList();
          this.refillMetaSelectedList();
        });


        $('.metacontainer input[name="sf_meta_search"]').on('keyup', this.handleMetaSearch.bind(this));
        $('.metacontainer .metalist').on('click', '.metaelement', this.handleMetaSelect.bind(this));
        $('.metacontainer .metaselected').on('click', '.metaelement', this.handleMetaUnselect.bind(this));
        $('#sf_btn_metaselected_clear').on('click', this.handleMetaSelectedClear.bind(this));
      }

      if($('#metatree').length > 0) {
        $.getJSON(this.routes['sf.meta.status'], result => {
          if(result.sfpackeerror) {
            return;
          }
          if(result.avail) {
            this.loadMetaTree();
          } else if(result.meta) {
            $('.metadownloader').show();
            this.fetchMeta();
          } else if(result.package) {
            $('.devdownloader').show();
            this.fetchMeta();
          }
        });

        $('#tree_selectall').on('click', this.metaTree_selectAll.bind(this));
        $('#tree_unselectall').on('click', this.metaTree_unselectAll.bind(this));
      }
    },
    loadAutoTree: function(overrideFilter) {
      let oldVal = $('#autometadatetime').val();
      if(oldVal) {
        overrideFilter = oldVal;
      }
      let momDefault = moment().subtract(24, 'hours');
      if(overrideFilter) {
        momDefault = moment(overrideFilter, 'HH:mm MM/DD/YYYY');
      }

      let filter = momDefault.toISOString();
      let picker = momDefault.format('HH:mm MM/DD/YYYY');

      $.getJSON(this.routes['sf.meta.auto.tree']+"?treemode=1&filter="+filter, autoTreeResult => {
        $('#tab-meta-auto-pane .autometadownloader').hide();

        if(this.metaAutoTree) {
          this.metaAutoTree.destroy();
        }
        this.metaAutoTree = $('#autometatree').tree({
          uiLibrary: 'bootstrap',
          dataSource: autoTreeResult.meta,
          primaryKey: 'id',
          checkboxes: true,
          iconsLibrary: 'fontawesome',
          border: true
        });
        this.metaAutoTree.checkAll();
        $('#autometaNumbers-types-val').text(autoTreeResult.nums.types);
        $('#autometaNumbers-total-val').text(autoTreeResult.nums.total);
        $('#autometaFilterContainer').show();
        if(this.dtPicker) {
          this.dtPicker.destroy();
        }
        $('#prepareAutoFetchedBtn').val(picker);
        this.dtPicker = $('#autometadatetime').datetimepicker({
          footer: true,
          modal: true,
          value: picker,
          change: function (e) {
            console.log('Change is fired', this.dtPicker.value());
            $('#tab-meta-auto-pane .autometadownloader').show();
            this.loadAutoTree(this.dtPicker.value());
          }.bind(this)
        });
      });
    },
    handleMetaSearch: function(e) {
      this.metaCurrentSearch = e.target.value;
      this.refillMetaList();
    },
    handleMetaSelect: function(e) {
      //console.log(e);
      if(e.target.innerText && e.target.innerText.length > 0 && this.metaList.includes(e.target.innerText)) {
        let selected = $('#sf_meta_selected').val();
        selected += selected.length > 0 ? ','+e.target.innerText : e.target.innerText;
        $('#sf_meta_selected').val(selected);
        this.refillMetaSelectedList();

        this.metaList = this.metaList.filter((value, index, arr) => {
          return value != e.target.innerText;
        });
        this.refillMetaList();
        this.setFetchMetaButton(true);
      }
    },
    handleMetaUnselect: function(e) {
      if(e.target.innerText && e.target.innerText.length > 0 && this.originalMetaList.includes(e.target.innerText)) {
        let selected = $('#sf_meta_selected').val();
        const regex = new RegExp(e.target.innerText);
        if(!regex.test(selected)) {
          return;
        }

        let sel_list = selected.replace(' ', '').split(',');
        sel_list = sel_list.filter((value, index, arr) => {
          return value != e.target.innerText;
        });
        $('#sf_meta_selected').val(sel_list.join(','));
        this.refillMetaSelectedList();
        this.metaList.push(e.target.innerText);
        this.refillMetaList();
        this.setFetchMetaButton(sel_list.length > 0);
      }
    },
    handleMetaSelectedClear: function(e) {
      e.preventDefault();
      this.metaList = JSON.parse(JSON.stringify(this.originalMetaList));
      $('#sf_meta_selected').val('');
      this.refillMetaList();
      this.refillMetaSelectedList();
      this.setFetchMetaButton(false);
    },
    refillMetaSelectedList: function() {
      let selected = $('#sf_meta_selected').val();
      if(selected && selected.length > 0) {
        let sel_list = selected.replace(' ', '').split(',').sort();
        const htmlstring = sel_list.map(d => {
          return '<div class="metaelement">' + d + '</div>';
        }).join('');
        $('.metacontainer .metaselected').html(htmlstring);
      } else {
        $('.metacontainer .metaselected').html('');
      }
    },
    refillMetaList: function() {
      let result = [];
      if(this.metaList && this.metaList.length > 0) {
        this.metaList.sort();
        let regex;
        if(this.metaCurrentSearch && this.metaCurrentSearch.length >= 2) {
          regex = new RegExp(this.metaCurrentSearch, 'i');
        }
        const htmlstring = this.metaList.map(d => {
          if((this.metaCurrentSearch && this.metaCurrentSearch.length >= 2 && regex.test(d)) || !this.metaCurrentSearch || this.metaCurrentSearch.length <= 1) {
            return '<div class="metaelement">' + d + '</div>';
          }
        }).join('');
        $('.metacontainer .metalist').html(htmlstring);
      }
    },
    metaTree_unselectAll: function(e) {
      e.preventDefault();
      //console.log(this.metaTree);
      this.metaTree.uncheckAll();
    },
    metaTree_selectAll: function(e) {
      e.preventDefault();
      //console.log(this.metaTree);
      this.metaTree.checkAll();
    },
    loadMetaTree: function () {
      $.ajax(this.routes['sf.meta.tree'])
        .done((dataTree) => {
          this.metaTree = $('#metatree').tree({
            uiLibrary: 'bootstrap',
            dataSource: dataTree,
            primaryKey: 'root',
            checkboxes: true,
            iconsLibrary: 'fontawesome',
            border: true
          });
          this.metaTree.checkAll();
          this.metaTree.on('checkboxChange', function(e, node, record, state) {
            console.log("checkboxChange", arguments);
          });
          const selections = this.metaTree.getCheckedNodes();
          $('#meta_to_push').val(JSON.stringify(selections));
        })
        .fail((jqx, error) => {
          console.log('error downloading dev tree', error);

          if(jqx.status == 401) {
            document.location.reload();
          }
        })
    },
    fetchMeta: function () {
      $.ajax({
        url: this.routes['form.sf.meta'],
        method: 'POST'
      })
        .done((result, status, jqx) => {
          if (jqx.status != 200) {
            console.log('error downloading package or meta', result)
            $('div.devdownloader').hide();
            $('div.metadownloader').hide();
            $('div.deverror').show();
          } else {
            console.log('done requesting package or meta, reloading page...')
            document.location.reload();
          }
        })
        .fail((jqx, error) => {
          console.log('error downloading dev package', error)
          $('div.devdownloader').hide();
          $('div.metadownloader').hide();
          $('div.generalerror').show();
        })
    },
    setFetchMetaButton(toggle) {
      const el = $('div.meta-selector-container button.fetchbtn');

      el.text(toggle ? "Fetch meta" : "Fetch package");
    }
  }

  $(document).ready(() => {
    App.init()
  })
})
